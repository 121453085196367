<template>
  <div class="d-flex flex-column-fluid">
    <!--begin::Container-->
    <div class="container-fluid py-8">
      <div class="d-flex flex-row">
        <UserNav activePage="profile" activeTab="3"></UserNav>
        <!--begin::Content-->
        <div class="flex-row-fluid ml-lg-8">

          <div class="flex-row-fluid ">
            <div>
              <!--begin::Card-->
              <div class="card card-custom gutter-b">
                <div class="card-body">
                  <!--begin::Details-->
                  <div class="d-flex mb-2">
                    <!--begin: Pic-->
                    <div class="flex-shrink-0 mr-7 mt-lg-0 mt-3">
                      <div v-if="coachList.length > 0" class="symbol symbol-50">
                        <img :src="coachList[0].user.profileimage" alt="image" />
                        <i v-if="coachList[0].isTest" class="symbol-badge bg-danger"></i>
                        <i v-else class="symbol-badge bg-success"></i>
                      </div>
                      <div class="symbol symbol-50 symbol-primary d-none"></div>
                    </div>
                    <!--end::Pic-->
                    <!--begin::Info-->
                    <div class="flex-grow-1">
                      <!--begin::Title-->
                      <div class="d-flex justify-content-between flex-wrap mt-1">
                        <div class="d-flex mr-3">
                          <a v-if="coachList.length > 0" href="#" class="
                            text-dark text-hover-primary
                            font-size-h5 font-weight-bold
                            mr-3
                          ">{{ coachList[0].user.firstname }}
                            {{ coachList[0].user.lastname }}</a>
                          <a href="#">
                            <i class="flaticon2-correct text-success font-size-h5"></i>
                          </a>
                        </div>
                      </div>
                      <!--end::Title-->
                      <!--begin::Content-->
                      <div class="d-flex flex-wrap justify-content-between mt-1">
                        <div class="d-flex flex-column flex-grow-1 pr-8">
                          <div class="d-flex flex-wrap mb-4">
                            <a href="#" v-if="coachList.length > 0" class="
                              text-dark text-hover-primary
                              font-weight-bold
                              mr-lg-8 mr-5
                              mb-lg-0 mb-2
                            ">
                              <i class="flaticon2-new-email mr-2 font-size-lg"></i>{{ coachList[0].email }}</a>
                            <a href="#" class="
                              text-dark text-hover-primary
                              font-weight-bold
                              mr-lg-8 mr-5
                              mb-lg-0 mb-2
                            ">
                              <i class="flaticon-presentation mr-2 font-size-lg"></i>Coach</a>
                            <a v-if="coachList.length > 0" href="#"
                              class="text-dark text-hover-primary font-weight-bold">
                              <i class="flaticon-map mr-2 font-size-lg"></i>{{ programCoachList.category[0] }}</a>
                          </div>
                          <div class="separator separator-solid mb-4"></div>
                          <span v-if="coachList.length > 0" class="font-weight-bold text-dark"
                            v-html="nl2br(programCoachList.coach.bio)"></span>
                        </div>
                      </div>
                      <!--end::Content-->
                    </div>
                    <!--end::Info-->
                  </div>
                  <!--end::Details-->
                </div>
              </div>
              <!--end::Card-->

              <div class="row">
                <div class="col-xxl-6">
                  <div class="col-md-12 col-lg-12 col-xxl-12 pl-0 pr-0">
                    <div class="card card-custom gutter-b card-stretch">
                      <!--begin::Body-->
                      <div class="card-body">
                        <!--begin::Top-->
                        <div class="d-flex align-items-center">
                          <!--begin::Info-->
                          <div class="d-flex flex-column flex-grow-1">
                            <a href="#" class="
                              text-dark text-hover-primary
                              mb-1
                              font-size-lg font-weight-bolder
                            " v-if="coachList.length > 0">{{ programCoachList.title }}</a>
                          </div>
                          <!--end::Info-->
                        </div>
                        <!--end::Top-->
                        <!--begin::Bottom-->
                        <div class="pt-3">
                          <p class="text-dark font-weight-normal font-size-lg" v-if="coachList.length > 0"
                            v-html="nl2br(programCoachList.description)"></p>
                        </div>
                        <!--end::Bottom-->
                      </div>
                      <!--end::Body-->
                    </div>
                  </div>
                </div>

                <div class="col-lg-6">
                  <!--begin::List Widget 10-->
                  <div class="card card-custom gutter-b card-stretch">
                    <!--begin::Header-->
                    <div class="card-header border-0 pt-5">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark fs-15">Upcoming Sessions</span>
                        <span class="text-dark mt-3 font-weight-bold font-size-sm">All times are in EST</span>
                      </h3>
                    </div>
                    <!--end::Header-->
                    <!--begin::Body-->
                    <div class="card-body">
                      <!--begin::Item-->
                      <div class="mb-6" v-for="(item, i) in calendarDates" :key="i">
                        <!--begin::Content-->
                        <div class="d-flex align-items-center flex-grow-1">
                          <!--begin::Section-->
                          <div class="
                            d-flex
                            flex-wrap
                            align-items-center
                            justify-content-between
                            w-100
                          ">
                            <!--begin::Info-->
                            <div class="d-flex flex-column align-items-cente py-2 w-75">
                              <!--begin::Title-->
                              <span class="
                                text-dark
                                font-weight-bold
                                text-hover-primary
                                font-size-lg
                                mb-1
                              ">{{ timestampToDate(item.livestream_time) }}</span>
                              <!--end::Title-->
                            </div>
                            <!--end::Info-->
                            <!--begin::Label-->
                            <span v-if="item.isCanceled == false" class="
                              label label-lg label-light-ingomugreen label-inline
                              font-weight-bold
                              py-4
                            ">Scheduled</span>
                            <span v-else class="
                              label label-lg label-light-danger label-inline
                              font-weight-bold
                              py-4
                            ">Cancelled</span>
                            <!--end::Label-->
                          </div>
                          <!--end::Section-->
                        </div>
                        <!--end::Content-->
                      </div>
                      <!--end::Item-->
                    </div>
                    <!--end: Card Body-->
                  </div>
                  <!--end: Card-->
                  <!--end: List Widget 10-->
                </div>
              </div>
            </div>
            <!-- <div class="col-md-5 col-lg-12 col-xxl-5"></div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import nl2brMixin from "@/mixins/nl2br";
import UserNav from "@/view/layout/header/UserNav.vue";

export default {
  name: "coachdetail",
  mixins: [nl2brMixin],
  components: {
    UserNav
  },
  mounted() {
    this.$store.commit("CHANGE_TABINDEX", { tabIndex: 2 });
    this.$store.commit("CHANGE_ACTIVETAB", { activeTab: 2 })
    this.$store.dispatch("getProgramCoachDetail", {
      coachId: this.$route.params.coachId,
    });
    this.$store.dispatch("getCoachCalSchedule", {
      coachListId: this.$route.params.coachId,
    });
  },
  methods: {
    timestampToDate(ts) {
      let newdtt = new Date(ts.seconds * 1000).toUTCString().toString();
      return `${newdtt.substring(0, newdtt.length - 7)} EST`;
    },
  },
  computed: {
    ...mapGetters({
      coachList: "programCoachDetail",
      programVideo: "programVideo",
      programCoachList: "programCoachList",
      calendarDates: "calendarDates",
    }),
  },
};
</script>

<style scoped>
.label-light-ingomugreen {
  color: #46c346;
}
</style>
